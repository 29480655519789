<template>
  <div class="view-group">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>

    <PageHeader
      :has-actions="isAdmin"
      :has-back="true"
      :title="title"
      @back="goBack"
    >
      <md-button
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon "
        @click="isRenameOpen = true"
      >
        <MeepIconEdit class="app-icon" />
      </md-button>
      <md-button
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon "
        @click="isSelectCompaniesModalOpen = true"
      >
        <MeepIconActionAdd class="app-icon-stroke-inverted" />
      </md-button>
      <md-button
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="header-action viewgroup-delete-button"
        @click="onDeleteGroup"
      >
        {{ $t("view-group.delete") }}
      </md-button>
    </PageHeader>
    <div class="view-group-content page-layout">
      <div class="view-group-content-column">
        <md-card v-if="!isLoading && error" class="page-list">
          <md-card-content>
            Groupe non trouvable
            <md-empty-state />
          </md-card-content>
        </md-card>

        <LoadingCard v-if="isLoading" />

        <template v-else>
          <sortable-list
            v-if="groupe.companies"
            :has-pagination="false"
            :items="groupe.companies"
            :parameters="listParameters"
            :title="$t('view-group.list-title')"
            default-sort="name"
            selectable="multiple"
            @selected="onSelect"
            @item-click="itemClick"
          />
        </template>

        <ConfirmActionModal
          v-if="isConfirmModalOpen"
          :object-to-act-upon="groupe"
          :text="confirmDeleteModalText"
          @close="isConfirmModalOpen = false"
          @confirm="onModalAction"
        />

        <select-items-modal
          v-if="isSelectCompaniesModalOpen && groupe.companies"
          :items-list="companiesCatalog"
          :selected-list="groupe.companies"
          :text="selectCompaniesModalText"
          @close="isSelectCompaniesModalOpen = false"
          @saved="onAddCompanies"
        >
          <template #icon>
            <MeepIconSettingCompanies class="app-icon-color" />
          </template>
        </select-items-modal>
      </div>
      <div class="view-group-content-column">
        <md-card v-if="isRenameOpen" class="view-group-card-rename meep-form">
          <p class="view-group-card-title md-layout">
            {{ $t("groupes.rename-title") }}
          </p>
          <div class="meep-input">
            <md-field>
              <label>
                {{ $t("groupes.rename-label") }}
              </label>
              <md-input v-model="groupe.name" name="lastname" type="lastname">
              </md-input>
            </md-field>
          </div>
          <div class="md-layout md-alignment-center-right">
            <md-button
              :class="{
                'md-secondary-inverted': !isJEP,
                'md-alternate': isJEP,
              }"
              @click="isRenameOpen = false"
            >
              {{ $t("groupes.rename-cancel") }}
            </md-button>
            <md-button
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              @click="onUpdateName"
            >
              {{ $t("groupes.rename-confirm") }}
            </md-button>
          </div>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
/* Modèles */
import groupesModel from "../../model/groupes";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import ConfirmActionModal from "@/components/modal/confirm-action";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconEdit from "@/components/icons/MeepIconEdit.vue";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";

import selectItemsModal from "../../components/modal/select-items";
import companies from "@/model/companies";

export default {
  name: "ViewGroup",
  components: {
    PageHeader,
    LoadingCard,
    ConfirmActionModal,
    Banner,
    MeepIconSettings,
    MeepIconEdit,
    MeepIconActionAdd,
    selectItemsModal,
    MeepIconSettingCompanies,
  },

  data() {
    return {
      action: "",
      isDebug: false,
      title: "groupe",
      groupe: {},
      isConfirmModalOpen: false,
      isSelectCompaniesModalOpen: false,
      isRenameOpen: false,
      isLoading: false,
      error: false,
      companiesCatalog: [],
      selectedList: [],
      newCompanies: [],

      selectCompaniesModalText: {
        header: this.$t("groupes.view.company-modal-text"),
      },
      confirmGlobalCancelModalText: {
        header: "Des modifications n'ont pas étés sauvegardées",
        body() {
          return "";
        },
        question: "Êtes-vous sûr de vouloir quitter cette page ?",
      },
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isJEP"]),

    listParameters() {
      return [
        {
          name: this.$t("groupes.table-header.name"),
          key: "name",
          width: "55%",
        },
        {
          name: this.$t("groupes.table-header.siren"),
          key: "siren",
          width: "20%",
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
          width: "10%",
        },
      ];
    },

    confirmDeleteModalText() {
      switch (this.action) {
        case "delete_group": {
          return {
            header: this.$t("menu.groupes"),

            body: object => {
              return this.$t("groupes.modal.delete-group-body-context", {
                name: object.name,
              });
            },

            question: this.$t("groupes.modal.question"),
          };
        }

        default: {
          return {
            header: this.$t("menu.groupes"),

            body: () => {
              return this.$t("groupes.modal.delete-company-body-context", {
                length: this.selectedList.length,
              });
            },

            question: this.$t("groupes.modal.question"),
          };
        }
      }
    },
  },

  watch: {
    $route: "loadData",
  },

  mounted() {
    this.loadData();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    async loadData() {
      try {
        this.isLoading = true;
        this.groupe = await groupesModel.get(this.$route.params.id);
        this.companiesCatalog = await companies.getAll();
        this.title = this.groupe.name;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
        this.error = true;
      } finally {
        this.isLoading = false;
      }
    },

    async onDelete() {
      try {
        await groupesModel.remove(this.groupe.id);
        this.$toasted.global.AppSucces({
          message: "Le groupe " + this.groupe.name + " a été bien supprimé",
        });

        await this.$router.push("/dashboard/setting/groupes");
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async onDeleteCompany(companyId) {
      try {
        await groupesModel.removeCompany(this.groupe.id, companyId);
        this.groupe = await groupesModel.get(this.$route.params.id);
        this.$toasted.global.AppSucces({
          message: this.$t("groupes.change-information"),
        });
      } catch (error) {
        console.log("Error goTo", error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onModalAction() {
      switch (this.action) {
        case "delete_group": {
          this.onDelete();
          break;
        }
        case "delete_company": {
          await Promise.all(
            this.selectedList.map(company => {
              this.onDeleteCompany(company.id);
            }),
          );
          break;
        }
      }
    },

    async onUpdateName() {
      try {
        await groupesModel.update(this.groupe);
        this.title = this.groupe.name;
        this.$toasted.global.AppSucces({
          message: this.$t("groupes.change-information"),
        });
        this.isRenameOpen = false;
      } catch (error) {
        console.log("Error", error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onAddCompanies(companies) {
      let oldCompanies = this.groupe.companies.filter(company => {
        return !(
          companies.find(element => {
            return element.id === company.id;
          }) !== undefined
        );
      });

      let newCompanies = companies.filter(company => {
        return !(
          this.groupe.companies.find(element => {
            return element.id === company.id;
          }) !== undefined
        );
      });

      try {
        await Promise.all(
          oldCompanies.map(async company => {
            await groupesModel.removeCompany(this.groupe.id, company.id);
          }),
        );

        await Promise.all(
          newCompanies.map(async company => {
            await groupesModel.addCompany(this.groupe.id, company.id);
          }),
        );

        this.groupe.companies = companies;
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    itemClick({ item }) {
      if (this.selectedList.length <= 1) {
        this.selectedList = [item];
      }
      this.action = "delete_company";
      this.isConfirmModalOpen = true;
    },

    onDeleteGroup() {
      this.action = "delete_group";
      this.isConfirmModalOpen = true;
    },

    onSelect(items) {
      this.selectedList = items;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .view-group {
    .page-header {
      .page-header__item {
        margin-bottom: 0;
        align-items: flex-start;

        .page-header__actions {
          .md-button {
            &:last-child {
              width: fit-content;
              height: 35px;
              font-size: toRem(9);
              padding: 0 toRem(8);
              @include for-lg {
                height: 51px;
                font-size: toRem(14);
              }
            }
          }
        }
      }

      .page-header__tabs-search {
        display: none;
      }
    }

    .md-card.view-group-card-rename.md-theme-default {
      margin: toRem(54) 0 0 0;
    }

    &-content {
      padding-left: 40px;
      margin-bottom: 34px;
      height: 100%;

      display: flex;
      flex-direction: row;
      gap: toRem(20);

      .md-subheader {
        min-height: 24px;
      }

      &-column {
        flex: 1 1 50%;
      }

      .md-table-cell.md-table-cell-selection {
        padding: 0 24px;
      }

      .sortable-list {
        padding: 0px;

        .md-table-toolbar.md-theme-default {
          .md-title {
            font-size: toRem(18);
            @include for-lg {
              font-size: toRem(29);
            }
          }
        }
      }
    }

    &-card-rename {
      width: 325px;
      height: 170px;
      margin-top: 145px;
      padding: 28px 42px;
      border-radius: 27px;
      background-color: #ffffff;
      @include for-lg {
        width: 512px;
        height: 270px;
      }

      .md-button {
        margin: 0;
        margin-left: 20px;

        &.md-primary {
          padding: 0 23px;
        }
      }

      .meep-input {
        .md-input {
          height: 42px;
          margin-bottom: 1rem;
          font-size: toRem(10);
          @include for-lg {
            height: 66px;
            font-size: toRem(17);
          }
        }

        .md-has-value {
          label {
            font-size: toRem(9);
            @include for-lg {
              font-size: toRem(12);
            }
          }
        }
      }

      .md-alignment-center-right {
        flex-wrap: nowrap;
        .md-ripple {
          font-size: toRem(9);
          @include for-lg {
            font-size: toRem(14);
          }
        }
      }

      .view-group-card-title {
        font-size: 29px;
        font-family: var(--font-bold);
        margin-bottom: 10px;
      }
    }
  }
}
</style>
