<!-- SelectItemsModal -->
<template>
  <modal class="select-items-modal" @close="$emit('close')">
    <div class="select-items-modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>
    <!-- Corps -->
    <div class="modal-body">
      <!-- Titre -->
      <div class="modal-body-title">
        <slot name="icon">
          <MeepIconSearchi class="app-icon" />
        </slot>

        <p>{{ text.header }}</p>
      </div>
      <div class="modal-body-search-list">
        <search-bar v-model="query" modifier="large"></search-bar>

        <md-list class="modal-list">
          <md-list-item v-for="item in filter" :key="item.id">
            <span>
              {{ item.name }}
            </span>

            <md-checkbox :value="!isSelected(item)" @change="onChecked(item)">
              <!-- Pour des raisons obscures, la case s'affiche comme étant cochée seulement lorsque la value est 'false'... -->
            </md-checkbox>
          </md-list-item>
        </md-list>
      </div>
    </div>

    <div class="modal-footer">
      <md-button class="md-primary--inverted" @click.native="$emit('close')">
        Annuler
      </md-button>

      <md-button class="md-raised md-primary" @click.native="onSave">
        {{ textBtnSave }}
      </md-button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconSearchi from "@/components/icons/MeepIconSearchi";

export default {
  name: "SelectItemsModal",
  components: {
    MeepIconSearchi,
  },
  props: {
    /* La liste des items que l'on peut sélectionner */
    itemsList: {
      type: Array,
      required: true,
    },
    /* La liste des identifiants des items déjà selectionnés */
    selectedList: {
      type: Array,
      required: true,
    },
    /* Le texte à afficher (titre, etc...) */
    text: {
      type: Object,
      required: true,
    },
    selectType: {
      type: String,
      default: "multiple",
    },
    textBtnSave: {
      type: String,
      default: "Valider"
    }
  },
  data() {
    return {
      selectedListNew: this.selectedList.slice(),
      query: "",
    };
  },
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC"]),

    filter() {
      let context = this;
      return this.itemsList.filter(function (item) {
        return (
          item.name.toLowerCase().indexOf(context.query.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    /* Renvoyer la liste des id selectionnés et fermer le modal */
    onSave() {
      this.$emit("saved", this.selectedListNew);
      this.$emit("close", "close-after-save");
    },

    /* Appelé lorsque l'on coche une case */
    onChecked(item) {
      /* Trouver si l'item existe déjà dans la liste, si oui récupérer son index */
      let itemIndex = this.selectedListNew.findIndex(function (element) {
        return element.id === item.id;
      });

      if (this.selectType === "single") {
        this.selectedListNew = [item];
        return;
      }

      /* Si l'item est dans la liste, le retirer */
      if (itemIndex != -1) {
        this.selectedListNew.splice(itemIndex, 1);
        /* Sinon l'ajouter */
      } else {
        this.selectedListNew.push(item);
      }
    },
    /* Verifier si l'id de l'item est dans la liste */
    isSelected(item) {
      return (
        this.selectedListNew.find((element) => {
          return element.id === item.id;
        }) !== undefined
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.select-items-modal {
  &-header {
    .app-logo {
      svg {
        width: 105px;
      }
    }
  }

  .modal-container {
    width: 600px;
    height: 80vh;
    border-radius: 27px;
    padding: 10px 64px 60px 38px;
    @include for-lg {
      height: 91vh;
      width: 1000px;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .modal-body {
    padding: 0 20px;

    p {
      margin: 5px 0;
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        font-size: toRem(30);
        font-family: var(--font-bold);
        margin: toRem(20) auto;
        @include for-lg {
          font-size: toRem(47);
          margin: toRem(40) auto;
        }
      }

      .app-icon,
      .app-icon-color {
        width: 49px;
        height: 44px;
      }
    }

    &-search-list {
      width: 485px;
      margin: 0 auto;
      @include for-lg {
        width: 850px;
      }
    }
  }

  .modal-footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .modal-list {
    max-height: 255px;
    overflow-y: scroll;
    @include for-lg {
      max-height: 420px;
    }
    .md-list-item {
      border-bottom: 1px solid var(--bg-secondary, red);
      cursor: pointer;

      &-content {
        padding: toRem(20) toRem(54);
        @include for-lg {
          padding: toRem(35) toRem(54);
        }

        span {
          font-size: toRem(16);
          font-family: var(--font);
          @include for-lg {
            font-size: toRem(20);
          }
        }
      }
    }
  }

  .debug-log {
    max-height: 15vh;
    overflow-y: scroll;
  }
}
</style>
